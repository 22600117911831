import { createApp } from "vue";
import { initSentry } from "@/js/sentry.js";
import { Inkline } from "@inkline/inkline";
import PageComponent from "@/pages/auth/Signin.vue";
import FallbackLayout from "@/components/layouts/Fallback.vue";
import "iconify-icon";

const app = createApp({
  name: "App",
  components: {
    Page: PageComponent,
    Layout: window.layoutComponent ?? FallbackLayout,
  },
});
app.use(Inkline, {
  colorMode: "light",
});
initSentry(app);
app.mount("#app");
