<template>
  <label v-show="!invisible" class="turnstile" :class="{ hasError }" @blur="onBlur">
    <span ref="turnstileRef" />
  </label>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, toRef } from "vue";
import { useFormValidationError, useValidation } from "@inkline/inkline/composables";
const turnstileSrc = "https://challenges.cloudflare.com/turnstile/v0/api.js";
const turnstileLoadFunction = "cfTurnstileOnLoad";
let turnstileState =
  typeof window !== "undefined" ? (window.turnstile !== undefined ? "ready" : "unloaded") : "unloaded";
let turnstileLoad = {
  resolve: () => {},
  reject: () => {},
};

const props = defineProps({
  name: { type: String, default: "" },
  siteKey: { type: String, required: true },
  modelValue: { type: String },
  resetInterval: { type: Number, default: 295 * 1000 },
  size: { type: String, default: "normal" },
  theme: { type: String, default: "auto" },
  language: { type: String, default: "auto" },
  action: { type: String, default: "" },
  appearance: { type: String, default: "always" },
  renderOnMount: { type: Boolean, default: true },
  invisible: { type: Boolean, default: false },
  validate: { type: Boolean, default: true },
  error: { type: [Array, Boolean], default: () => ["touched", "dirty", "invalid"] },
});

const emit = defineEmits(["update:modelValue", "error", "unsupported"]);

defineExpose({ reset });

const resetTimeout = ref(undefined);
const widgetId = ref(undefined);
const turnstileRef = ref(undefined);

const name = toRef(props, "name");
const validate = toRef(props, "validate");
const { schema, onBlur: schemaOnBlur, onInput: schemaOnInput } = useValidation({ name, validate });
const error = toRef(props, "error");
const { hasError } = useFormValidationError({ schema, error });

const turnstileOptions = computed(() => ({
  sitekey: props.siteKey,
  theme: props.theme,
  language: props.language,
  size: props.size,
  action: props.action,
  appearance: props.appearance,
  callback: callback,
  "error-callback": errorCallback,
  "unsupported-callback": unsupportedCallback,
}));

onMounted(() => {
  const turnstileLoadPromise = new Promise((resolve, reject) => {
    turnstileLoad = { resolve, reject };
    if (turnstileState === "ready") resolve(undefined);
  });
  window[turnstileLoadFunction] = () => {
    turnstileLoad.resolve();
    turnstileState = "ready";
  };
  const ensureTurnstile = () => {
    if (turnstileState === "unloaded") {
      turnstileState = "loading";
      const url = `${turnstileSrc}?onload=${turnstileLoadFunction}&render=explicit`;
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.addEventListener("error", () => {
        turnstileLoad.reject("Failed to load Turnstile.");
      });
      document.head.appendChild(script);
    }
    return turnstileLoadPromise;
  };
  ensureTurnstile().then(() => {
    if (props.renderOnMount) render();
  });
});

onBeforeUnmount(() => {
  remove();
  clearTimeout(resetTimeout);
});

function onBlur(event) {
  schemaOnBlur(name, event);
}

function onInput(token) {
  emit("update:modelValue", token);
  schemaOnInput(name, token);
}

function unsupportedCallback() {
  emit("unsupported");
}

function errorCallback(code) {
  emit("error", code);
}

function callback(token) {
  onInput(token);
  startResetTimeout();
}

function reset() {
  if (window.turnstile) {
    if (schema.value && validate.value) {
      schema.value.dirty = false;
      schema.value.touched = false;
    }
    onInput("");
    window.turnstile.reset();
  }
}

function remove() {
  if (widgetId.value) {
    window.turnstile.remove(widgetId.value);
    widgetId.value = undefined;
  }
}

function render() {
  widgetId.value = window.turnstile.render(turnstileRef.value, turnstileOptions.value);
}

function startResetTimeout() {
  resetTimeout.value = setTimeout(reset, props.resetInterval);
}
</script>

<style lang="scss">
.turnstile {
  width: 300px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
  box-shadow:
    inset 1px 1px #666,
    inset -1px -1px #666;
  position: relative;

  .cf-turnstile-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px !important;
    height: 59px !important;
  }

  > div {
    translate: 0 5px;
  }
}
</style>
